import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Layout from '../components/layouts'
import HeadMeta from '../components/HeadMeta'
import * as ds from '../uiLibrary'
import { GatsbyHeroImage } from '../components/heroes'
import Helmet from 'react-helmet'
import { InfoContainerSection } from '../components'
import { Headline } from '../uiLibrary/Typographyv2'
import { LocationContext } from '../pages/index'
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form'
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form'
import styled from 'styled-components'
import { useLocale } from '../hooks/useLocale'

const FormWrap = styled.div`
  width: 90%;
  max-width: 500px;
  margin: 40px auto;
`

export const RegisterPage = ({ data, ...props }) => {
  const page = data.allRegisterJson.edges[0].node
  const bannerImage = data.banner.edges[0].node.childImageSharp
  const { loaded, error, formCreated } = useHubspotForm({
    region: 'na1',
    portalId: '5578647',
    formId: props.hubspotFormId || 'a136034e-48e8-4242-97b2-7d57b4656786',
    target: '#form-container',
  })

  const currentLocale = useLocale(props.location.pathname)

  return (
    <LocationContext.Provider value={props.location}>
      <Layout locale={currentLocale}>
        <HeadMeta
          pageTitle={page.pageTitle || 'Tonit'}
          pageDescription={page.pageDescription}
          socialTitle={page.socialTitle}
          socialDescription={page.socialDescription}
          socialImage={page.socialImage}
        />
        <ds.Background background={'#000000'}>
          <ds.FixedWidthContainer
            py={[ds.spacing.s5, ds.spacing.s6, ds.spacing.s6]}
          >
            <GatsbyHeroImage
              url={bannerImage.fluid}
              alt="Tonit - the app for motorcyclists"
            />
            <Headline
              textAlign={'center'}
              px={[ds.spacing.s3, ds.spacing.s4, ds.spacing.s5]}
              fontSize={[ds.typeScale.t6, ds.typeScale.t7, '4.998rem']}
              color={ds.brandColors.lightGreen}
            >
              {page.heroHeading}
            </Headline>
          </ds.FixedWidthContainer>
        </ds.Background>
        <ds.Background
          background={'#202020'}
          pb={[ds.spacing.s2, ds.spacing.s3, ds.spacing.s5]}
        >
          <InfoContainerSection
            title={page.introTitle}
            body1={page.introBody}
            pt={ds.spacing.s3}
            pb={ds.spacing.s3}
            triangle={true}
            index={1}
          />
          <ds.FixedWidthContainer
            bg="white"
            pb={ds.spacing.s6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {!loaded ? (
              <ds.Label style={{ textAlign: 'center' }}>
                Loading Form...
              </ds.Label>
            ) : null}

            <FormWrap>
              <div id="form-container"></div>
            </FormWrap>
          </ds.FixedWidthContainer>
        </ds.Background>
      </Layout>
    </LocationContext.Provider>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        banner: allFile(filter: { name: { regex: "/^genericheader*/" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        allRegisterJson {
          edges {
            node {
              heroHeading
              introTitle
              introBody
              introImage
              introImageAlt
              pageTitle
              socialTitle
              pageDescription
              socialImage
              socialDescription
            }
          }
        }
      }
    `}
    render={data => (
      <HubspotProvider>
        <RegisterPage data={data} {...props} />
      </HubspotProvider>
    )}
  />
)
