import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form'
import { RegisterPage as EnglishRegisterPage } from '../register'

const RegisterPage = ({ data, ...props }) => {
  return (
    <EnglishRegisterPage
      {...props}
      data={{ ...data, allRegisterJson: data.allRegisterEsJson }}
      hubspotFormId={'fe036264-45ea-4eec-a0bb-98d4f9230762'}
    />
  )
}

export const RegisterEs = props => (
  <StaticQuery
    query={graphql`
      query {
        banner: allFile(filter: { name: { regex: "/^genericheader*/" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 1440, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        allRegisterEsJson {
          edges {
            node {
              heroHeading
              introTitle
              introBody
              introImage
              introImageAlt
              pageTitle
              socialTitle
              pageDescription
              socialImage
              socialDescription
            }
          }
        }
      }
    `}
    render={data => (
      <HubspotProvider>
        <RegisterPage data={data} {...props} />
      </HubspotProvider>
    )}
  />
)

export default RegisterEs
